import { PWA_TOGGLE_SIDEBAR } from '../actions';

const INIT_STATE = {
  isOpen: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PWA_TOGGLE_SIDEBAR:
      return { ...state, isOpen: action.payload.status };
    default:
      return { ...state };
  }
};
