import RU_ICON from 'assets/img/icons/ru.svg';
import EN_ICON from 'assets/img/icons/en.svg';
import AZ_ICON from 'assets/img/icons/az.svg';

export const UserRole = {
  Staff: 0,
  Editor: 1,
};

export const UserRoles = {
  Staff: 'Staff',
  Manager: 'Manager',
  Admin: 'Admin',
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr', icon: EN_ICON },
  { id: 'ru', name: 'Russian', direction: 'ltr', icon: RU_ICON },
  { id: 'az', name: 'Azerbaijani', direction: 'ltr', icon: AZ_ICON },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/No-photo-m.png',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const loginPage = '/accounts/login';
export const adminRoot = '/';
export const staff_Url = 'staff';
export const manager_Url = 'manager';
export const admin_Url = 'admin';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = ['blueolympic'];
